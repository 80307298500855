<template>
  <div class="subtypes">
    <div class="subtypes__select">
      <p>{{ $t('incident-type') }}</p>
      <ui-select @input="changeSelectValue" :options="getTypes" v-model="incidentType" />
    </div>
    <Loader v-model="loader" />
    <Table
        v-if="incidentType.name && !loader"
        :title="$t('subtype')"
        :descriptionList="[{title: $t('title'), width: 440}, {title: $t('hints'), width: 100, paddingLeft: getColumns.length ? 0 : 60}]"
        :columns="getColumns"
        :actionText="$t('add-subtype')"
        :data="getSubtypes"
        @deleteRowField="deleteRowField($event)"
        @changeRowField="changeRowField($event)"
        @addRowField="addRowField($event)"
        @addColumn="addColumn($event)"
        @editColumn="editColumn($event)"
        @deleteColumn="deleteColumn($event)"
        @showEditColumnNumber="showEditColumnNumber($event)"
        @editColumnNumber="editColumnNumber($event)"
        @addNewNumber="addNewNumber($event)"
        @updateDescription="updateDescription($event)"
        :sort="true"
        addColumns
        hideEditIcon
        :columnsCount="getColumns.length"
        :typeId="incidentType.id"
        :isGlobeIcon="true"
        attention
    />
    <UiNotification :message="message" v-model="showNotification" />
  </div>
</template>

<script>
import UiSelect from "@/components/ui/UiSelect";
import Table from "@/components/ui/UiTable";
import Loader from "@/components/ui/UiLoader";
import {mapActions, mapGetters} from "vuex";
import UiNotification from "@/components/ui/UiNotification";
export default {
  name: "SubTypes",
  components: {UiNotification, Loader, Table, UiSelect},

  data() {
    return {
      incidentType: { name: '', value: '' },
      message: '',
      loader: false,
      showNotification: false,
    }
  },

  // mounted () {
  //    this.addColumnNumber({id: 1, orderSubtypeId: 28, amount: 30000, specValueId: null}).then((res) => {
  //      console.log(res)
  //    })
  // },

  methods: {
    ...mapActions(['types', 'subtypes', 'editSubtype', 'addSubtype', 'deleteType', 'addColumns', 'columns', 'addColumnNumber', 'editColumns', 'deleteColumns', 'deleteColumns']),
    changeSelectValue() {
      this.loader = true;

      this.subtypes(this.incidentType.id).then(() => {
        setTimeout(() => {
          this.loader = false
        },300)
      })

      this.columns({
        orderTypeId: this.incidentType.id
      })
    },
    deleteRowField(data) {
      this.loader = true;

      this.deleteType(data)
      .then(() => {
        this.subtypes(this.incidentType.id).then(() => {
          setTimeout(() => {
            this.loader = false
            this.message = this.$t('subtype-successfully-deleted')
            this.showNotification = true
          },500)
        })
      })
    },

    changeRowField(data) {
      this.loader = true;
      this.editSubtype({
        id: data.id,
        name: data.name,
        ord: +data.ord,
        typeId: data.typeId,
        nameIntEn: data.nameIntEn,
        description: data.description,
        descriptionIntEn: data.descriptionEn
      }).then(() => {
        this.subtypes(data.typeId)
          .then(() => {
            setTimeout(() => {
              this.loader = false
              this.message = this.$t('subtype-successfully-updated')
              this.showNotification = true
            }, 500)
          })
      })
    },

    updateDescription(data) {
      this.loader = true;
      this.editSubtype({
        id: data.id,
        name: data.name,
        ord: +data.ord,
        typeId: data.typeId,
        nameIntEn: data.nameIntEn,
        description: data.description,
        descriptionIntEn: data.descriptionEn
      }).then(() => {
        this.subtypes(data.typeId)
            .then(() => {
              setTimeout(() => {
                this.loader = false
                this.message = this.$t('hint-successfully-updated')
                this.showNotification = true
              }, 500)
            })
      })
    },

    addRowField(data) {
      this.loader = true;
      this.addSubtype({
        name: data.name,
        id: data.id,
        ord: +data.ord
      }).then((res) => {
        this.subtypes(res.typeId)
            .then(() => {
              setTimeout(() => {
                this.loader = false
                this.message = this.$t('subtype-successfully-added')
                this.showNotification = true
              }, 500)
            })
      })
    },
    addColumn(info) {
      const data = {
        orderTypeId: this.incidentType.id,
        specTypeId: null,
        name: info.name
      }
      this.loader = true
      this.addColumns(data).then(() => {
        this.subtypes(data.orderTypeId)
            .then(() => {
              this.columns({orderTypeId: this.incidentType.id})
              setTimeout(() => {
                this.loader = false
                this.message = this.$t('column-added')
                this.showNotification = true
              }, 500)
            })
      })
    },

    editColumn(item) {
      const data = {
        orderTypeId: this.incidentType.id,
        orderSubtypeId: null,
      }
      this.loader = true;
      this.editColumns({id: item.id, name: item.editForm}).then(() => {
        this.subtypes(data.orderTypeId)
            .then(() => {
              this.columns({orderTypeId: this.incidentType.id})
              this.$store.commit('closeEditColumn', item)
              setTimeout(() => {
                this.loader = false
                this.message = this.$t('column-updated')
                this.showNotification = true
              }, 500)
            })
      })
    },

    deleteColumn(item) {
      const data = {
        orderTypeId: this.incidentType.id,
        orderSubtypeId: null,
      }
      this.loader = true;
      this.deleteColumns({id: item.id}).then(() => {
        this.subtypes(data.orderTypeId)
            .then(() => {
              this.columns({orderTypeId: this.incidentType.id})
              setTimeout(() => {
                this.loader = false
                this.message = this.$t('column-deleted')
                this.showNotification = true
              }, 500)
            })
      })
    },

    editColumnNumber(data) {
      this.loader = true
      +data.number?.numVal || +data.amount ? this.loader = true : ''
      const info = {
        orderTypeId: this.incidentType.id,
        orderSubtypeId: null,
      }
      this.addColumnNumber({id: data.column.id, orderSubtypeId: data.row.id, amount: +data.amount || +data.number?.numVal, specValueId: null}).then(() => {
        this.subtypes(info.orderTypeId)
            .then(() => {
              this.columns({orderTypeId: this.incidentType.id})
              this.$store.commit('closeEditColumnNumber', data)
              setTimeout(() => {
                this.loader = false
                this.message = this.$t('column-updated')
                this.showNotification = true
              }, 500)
            })
      })
    },

    addNewNumber(data) {
      this.loader = true
      const info = {
        orderTypeId: this.incidentType.id,
        orderSubtypeId: null,
      }
      this.addColumnNumber({id: data.columnId, orderSubtypeId: data.rowId, amount: +data.number?.numVal || +data.amount, specValueId: null}).then(() => {
        this.subtypes(info.orderTypeId)
            .then(() => {
              this.columns({orderTypeId: this.incidentType.id})
              setTimeout(() => {
                this.loader = false
                this.message = this.$t('column-added')
                this.showNotification = true
              }, 500)
            })
      })
    }
  },
  computed: {
    ...mapGetters(['getTypes', 'getSubtypes', 'getOrderTypes', 'getColumns']),
  },
}
</script>

<style lang="scss" scoped>
.subtypes {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  min-height: 500px;

  &__select {
    display: flex;
    align-items: center;
    gap: 20px;
    background: #FFFFFF;
    box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
    border-radius: 20px;
    max-width: 490px;
    width: 100%;
    padding: 20px;

    p {
      font-size: 16px;
      line-height: 19px;
      color: #1B1A1F;
      white-space: nowrap;
    }
  }
}
::v-deep .tooltip__window {
  max-width: 300px !important;
}
</style>